import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app/app.component';
import { GrapesjsComponent } from './grapesjs/grapesjs.component';
import { UnlayerComponent } from './unlayer/unlayer.component';
import { MosaicoComponent } from './mosaico/mosaico.component';
import { HomeComponent } from './home/home.component';
import { EmailEditorModule } from 'angular-email-editor';
import { StripoComponent } from './stripo/stripo.component';
import { BeefreeComponent } from './beefree/beefree.component';

@NgModule({
  declarations: [
    AppComponent,
    GrapesjsComponent,
    HomeComponent,
    MosaicoComponent,
    StripoComponent,
    UnlayerComponent,
    BeefreeComponent,
  ],
  imports: [BrowserModule, AppRoutingModule, EmailEditorModule],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
