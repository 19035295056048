import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BeefreeComponent } from './beefree/beefree.component';
import { GrapesjsComponent } from './grapesjs/grapesjs.component';
import { HomeComponent } from './home/home.component';
import { MosaicoComponent } from './mosaico/mosaico.component';
import { StripoComponent } from './stripo/stripo.component';
import { UnlayerComponent } from './unlayer/unlayer.component';

const routes: Routes = [
  { path: 'beefree', component: BeefreeComponent },
  { path: 'grapesjs', component: GrapesjsComponent },
  { path: 'home', component: HomeComponent },
  { path: 'mosaico', component: MosaicoComponent },
  { path: 'stripo', component: StripoComponent },
  { path: 'unlayer', component: UnlayerComponent },
  { path: '**', redirectTo: 'home' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
