import { Component, OnInit } from '@angular/core';
import Bee from '@mailupinc/bee-plugin';
import { FileService } from '../file.service';

@Component({
  selector: 'app-beefree',
  templateUrl: './beefree.component.html',
})
export class BeefreeComponent implements OnInit {
  private readonly clientId = '855d26c3-a20d-43a2-8422-e91fff015d42';
  private readonly clientSecret =
    'tYA6eRhtklb7HgYwe7JYxtE5WZkYCX7awR1aqbhEgcGYPMDEUeq4';
  private readonly beeConfig = {
    // required
    uid: 'uniqueIdOfaUser', // set by us
    container: 'bee-editor',
    // optional
    language: 'de-DE',
    mergeTags: [
      {
        name: 'Vorname',
        value: '[first-name]',
      },
      {
        name: 'Nachname',
        value: '[first-name]',
      },
    ],
    onSave: (jsonFile, htmlFile) => {
      this.saveTemplate(jsonFile);
    },
    onSaveAsTemplate: (jsonFile) => {
      this.saveTemplate(jsonFile);
    },
    onSend: (htmlFile) => {
      window.alert('Dies ist gerade nicht möglich');
    },
    onError: console.error,
  };
  private readonly template = {
    page: {
      title: 'Template Base',
      description: 'Test template for BEE',
      template: { name: 'template-base', type: 'basic', version: '0.0.1' },
      body: {
        type: 'mailup-bee-page-proprerties',
        container: { style: { 'background-color': '#FFFFFF' } },
        content: {
          style: {
            'font-family': "Arial, 'Helvetica Neue', Helvetica, sans-serif",
            color: '#000000',
          },
          computedStyle: {
            linkColor: '#0068A5',
            messageBackgroundColor: 'transparent',
            messageWidth: '500px',
          },
        },
        webFonts: [],
      },
      rows: [
        {
          type: 'one-column-empty',
          container: {
            style: {
              'background-color': 'transparent',
              'background-image': 'none',
              'background-repeat': 'no-repeat',
              'background-position': 'top left',
            },
          },
          content: {
            style: {
              'background-color': 'transparent',
              color: '#000000',
              width: '500px',
              'background-image': 'none',
              'background-repeat': 'no-repeat',
              'background-position': 'top left',
            },
            computedStyle: {
              rowColStackOnMobile: true,
              rowReverseColStackOnMobile: false,
            },
          },
          columns: [
            {
              'grid-columns': 12,
              modules: [],
              style: {
                'background-color': 'transparent',
                'padding-top': '5px',
                'padding-right': '0px',
                'padding-bottom': '5px',
                'padding-left': '0px',
                'border-top': '0px solid transparent',
                'border-right': '0px solid transparent',
                'border-bottom': '0px solid transparent',
                'border-left': '0px solid transparent',
              },
            },
          ],
        },
      ],
    },
    comments: {},
  };
  private beePlugin = new Bee();

  private _loaded = false;
  get loaded(): boolean {
    return this._loaded;
  }

  constructor(private fileService: FileService<any>) {}

  ngOnInit(): void {
    this.initEditor();
  }

  export() {
    this.beePlugin.saveAsTemplate((template) => {
      this.saveTemplate(template);
    });
  }

  import() {
    this.fileService.openFile().then((template) => {
      this.beePlugin.load(template);
    });
  }

  private async initEditor() {
    await this.beePlugin.getToken(this.clientId, this.clientSecret);
    await this.beePlugin.start(this.beeConfig, this.template);
    this._loaded = true;
  }

  private saveTemplate(template) {
    this.fileService.downloadFile(template, 'bee-export.json');
  }
}
