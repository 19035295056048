<div class="hw-100 flex-col">
  <div class="p-1 flex flex-justify flex-middle">
    <a [routerLink]="'/'">zurück</a>
    <h2>Stripo</h2>
    <div>
      <button (click)="import()" [disabled]="!loaded">Import</button>
      <button (click)="export()" [disabled]="!loaded">Export</button>
    </div>
  </div>

  <div class="grow flex relative">
    <div class="cover flex flex-center flex-middle behind">
      <div class="spinner fz-250"></div>
    </div>
    <!--Plugin containers -->
    <div class="grow-1 mxw-300" id="stripoSettingsContainer"></div>
    <div class="grow-2" id="stripoPreviewContainer"></div>
  </div>
</div>
