import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FileService<T> {
  /** Saves a string of data to a file. */
  downloadFile(data: T, filename = 'data.json'): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        const blob = typeof data === 'string' ? data : JSON.stringify(data);
        const file = new window.Blob([blob], { type: 'json' });

        const anchor = document.createElement('a');
        anchor.style.display = 'none';

        const fileURL = URL.createObjectURL(file);
        anchor.href = fileURL;
        anchor.download = filename;
        anchor.click();

        anchor.remove();

        resolve();
      } catch (err) {
        reject(err);
      }
    });
  }

  /** Opens the file browser to choose a file. */
  openFile(): Promise<T> {
    return new Promise((resolve, reject) => {
      try {
        const fileReader = new FileReader();
        fileReader.onload = () => {
          let data = '';
          if (fileReader.result instanceof ArrayBuffer) {
            const dec = new TextDecoder('utf-8');
            data = dec.decode(fileReader.result);
          } else {
            data = fileReader.result || '';
          }
          resolve(JSON.parse(data));
        };

        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.addEventListener('change', () => {
          const files = input.files;
          if (!files || files.length === 0) {
            return;
          }
          fileReader.readAsText(files[0] as File);
          input.remove();
        });
        input.click();
      } catch (err) {
        reject(err);
      }
    });
  }
}
