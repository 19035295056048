import { Component } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent {
  readonly imgWidth = 200;
  readonly editors = [
    {
      title: 'Stripo',
      link: '/stripo',
      img:
        'https://f.hubspotusercontent20.net/hubfs/5219928/__hs-marketplace__/logo-vertical-1.png',
      comments: [
        'Saas, on premise möglich',
        'Speichern auf deren Servern möglich, kann unterdrückt werden',
        'Free Account verfügbar',
        'mehr Funktionen und Speicherplatz in bezahlten Plänen',
      ],
    },
    {
      title: 'Unlayer',
      link: '/unlayer',
      img:
        'https://uploads-ssl.webflow.com/5daaade3e3e3f0d01b1daa77/5daab5a98bfff67898127df6_dark_logo_transparent%402x.png',
      comments: [
        'Saas, on premise möglich',
        'Nutzung ohne Account möglich',
        'mehr Funktionen wenn mit Account verbunden',
        'sendet nach jeder Aktion Nachricht an unlayer server',
        'Nachricht enthält nur Aktion, kein Text oder Daten',
      ],
    },
    {
      title: 'Beefree',
      link: '/beefree',
      img:
        'https://developers.beefree.io/static/assets/img/logo-bee-plugin.png',
      comments: [
        'Saas, on premise möglich',
        'Free Account verfügbar',
        'Nutzung nur mit gültigen Accountdaten',
        'umfassende Rechte und Projektverwaltung',
      ],
    },
    {
      title: 'Mosaico',
      link: '/mosaico',
      img: './assets/mosaico.png',
      comments: [
        'Open Source und free',
        'Backend muss selbst gehostet werden',
        'es muss sehr viel selbst konfiguriert werden',
        'ansonsten ist der Editor einfach leer',
        'sehr kompliziert zu implementieren',
        'sehr schlecht dokumentiert',
        'Updates sehr unregelmäßig',
      ],
    },
    {
      title: 'Grapesjs',
      link: '/grapesjs',
      img: 'https://images.opencollective.com/grapesjs/3cc6d9d/logo/256.png',
      comments: [
        'Open Source und free',
        'Erweiterbar durch zahlreiche Plugins',
        'dadurch etwas undurchsichtig',
      ],
    },
  ];
}
