import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { EmailEditorComponent } from 'angular-email-editor';
import { FileService } from '../file.service';

@Component({
  selector: 'app-unlayer',
  templateUrl: './unlayer.component.html',
})
export class UnlayerComponent {
  readonly options = {
    displayMode: 'email',
    locale: 'de-DE',
    mergeTags: {
      first_name: {
        name: 'Vorname',
        value: '{{first_name}}',
        sample: 'John',
      },
      last_name: {
        name: 'Nachname',
        value: '{{last_name}}',
        sample: 'Doe',
      },
    },
    safeHtml: true,
  };

  private _loaded = false;
  get loaded(): boolean {
    return this._loaded;
  }

  @ViewChild(EmailEditorComponent)
  private emailEditor: EmailEditorComponent;

  constructor(
    private fileService: FileService<any>,
    private changeDetector: ChangeDetectorRef,
  ) {}

  onLoaded() {
    this._loaded = true;
    this.changeDetector.detectChanges();
  }

  import() {
    this.fileService.openFile().then((data) => {
      this.emailEditor.loadDesign(data);
    });
  }

  export() {
    this.emailEditor.saveDesign((data) => {
      this.fileService.downloadFile(data, 'unlayer-export.json');
    });
  }
}
