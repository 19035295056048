<div class="hw-100 flex flex-center flex-middle">
  <div>
    <div>
      <div class="flex flex-bottom">
        <div class="grow m-1" *ngFor="let editor of editors">
          <div class="flex-col flex-middle">
            <img
              [routerLink]="editor.link"
              [src]="editor.img"
              alt=""
              [width]="imgWidth"
              class="pointer"
            />
            <a [routerLink]="editor.link">{{ editor.title }}</a>
          </div>
        </div>
      </div>
      <div class="flex flex-top">
        <ul class="grow m-1" *ngFor="let editor of editors">
          <li *ngFor="let comment of editor.comments">{{ comment }}</li>
        </ul>
      </div>
    </div>

    <div class="p-2 mt-2 text-center fz-150">
      <p>Bitte bewerte den jeweiligen Editor, den du getestet hast.</p>
      <p class="mt-1 mb-1"><a href="https://forms.office.com/r/YzEhjehKHg" target="_blank">Zur Umfrage</a></p>
      <p class="fz-70"><em>Die Umfrage muss für jeden Editor erneut ausgefüllt werden.</em></p>
    </div>
  </div>
</div>
