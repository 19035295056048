<div class="hw-100 flex-col">
  <div class="p-1 flex flex-justify flex-middle">
    <a [routerLink]="'/'">zurück</a>
    <h2>Grapesjs</h2>
    <div>
      <button (click)="import()" [disabled]="!loaded">Import</button>
      <button (click)="export()" [disabled]="!loaded">Export</button>
    </div>
  </div>

  <div class="grow" id="gjs"></div>
</div>
