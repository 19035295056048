<div class="hw-100 flex-col">
  <div class="p-1 flex flex-justify flex-middle">
    <a [routerLink]="'/'">zurück</a>
    <h2>Mosaico</h2>
    <div></div>
  </div>

  <div class="grow p-2 flex flex-center flex-middle">
    <div class="text-center">
      <p>Mosaico ist sehr komplex und benötigt ein umfangreiches Setup im Backend. Das hätte hier den Rahmen gesprengt.</p>
      <p>Generell ist das Konfigurieren dieses Editors sehr aufwendig, man muss fast alles selbst bauen.</p>
      <p>Mosaico hat allerdings auf der Webseite eine Live-Demo des Editors verfügbar:</p>
      <p><a href="https://mosaico.io/#about" target="_blank">Zum Editor</a></p>
    </div>
  </div>
</div>
