import { Component, OnDestroy, OnInit } from '@angular/core';
import { FileService } from '../file.service';

@Component({
  selector: 'app-stripo',
  templateUrl: './stripo.component.html',
  styles: [],
})
export class StripoComponent implements OnInit, OnDestroy {
  private _loaded = false;
  get loaded(): boolean {
    return this._loaded;
  }

  constructor(private fileService: FileService<any>) {}

  ngOnInit(): void {
    this.loadDemoTemplate().then((template) => this.initPlugin(template));
  }

  ngOnDestroy(): void {
    (window as any)?.StripoApi?.stop();
  }

  export() {
    (window as any)?.StripoApi?.getTemplate((html, css) => {
      this.fileService.downloadFile({ html, css }, 'stripo-export.json');
    });
  }

  import() {
    this.fileService.openFile().then((template) => this.initPlugin(template));
  }

  private async initPlugin(template: { html: string; css: string }) {
    this._loaded = false;
    (window as any).Stripo.init({
      settingsId: 'stripoSettingsContainer',
      previewId: 'stripoPreviewContainer',
      html: template.html,
      css: template.css,
      apiRequestData: {
        emailId: 'test543951',
      },
      getAuthToken: this.getAuthToken,
      locale: 'de',
    });
    this._loaded = true;
  }

  private getAuthToken(callback) {
    fetch('https://plugins.stripo.email/api/v1/auth', {
      method: 'post',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
      body: JSON.stringify({
        pluginId: 'a9f668658d33428aa9d4139a285c4b2c',
        secretKey: 'dffcc3f62cfa4137afe402327e98b833',
      }),
    }).then((res) => {
      res.json().then((data) => {
        callback(data.token);
      });
    });
  }

  private async loadDemoTemplate() {
    const htmlRes = await fetch(
      'https://raw.githubusercontent.com/ardas/stripo-plugin/master/Public-Templates/Basic-Templates/Trigger%20newsletter%20mockup/Trigger%20newsletter%20mockup.html',
    );
    const html = await htmlRes.text();

    const cssRes = await fetch(
      'https://raw.githubusercontent.com/ardas/stripo-plugin/master/Public-Templates/Basic-Templates/Trigger%20newsletter%20mockup/Trigger%20newsletter%20mockup.css',
    );
    const css = await cssRes.text();

    return { html, css };
  }
}
